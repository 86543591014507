// change the theme
$theme-colors: (
  "primary": #fa9641,
  "danger": #ff4136,
);
$btn-color: #ff4136;

@import "~bootstrap/scss/bootstrap.scss";

.jumbotron {
  padding: 24rem 12rem;
  background-image: url("https://images.unsplash.com/photo-1544785349-c4a5301826fd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");

  /* Full height */
  height: 57rem;
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  border-radius: 0rem;
  // @media (max-width: 800px) {
  //   padding: 2rem 1rem;
  // }
}

body {
  color: #fa9641;
}

h1.display-2 {
  padding-bottom: 4rem;
}

a.link {
  color: white;
  text-decoration: none;
}

.container {
  margin: 0 auto;
  max-width: 2000px;
  padding: 0;
}

.footer {
  bottom: 0;
  width: 100%;
  position: fixed;
  text-align: center;
  justify-content: center;
  border-radius: 5%;

  a {
    color: white;
    text-decoration: none;
  }
}
.display-1 {
  margin-top: 10rem;

  @media (max-width: 950px) {
    margin-top: 3rem;
  }
}

.cta {
  position: absolute;
  top: 10rem;
  text-align: center;
  // border: 1px solid red;
}

.btn {
  text-decoration: none;
  margin: 0 auto;
  &:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
}
.title {
  color: azure;
  position: absolute;
  top: 1px;
  left: 1px;
}

//Booking Form

form {
  width: 50%;
  background-color: white;
  border: 1px solid gray;
  box-shadow: 1px 1px;
  border-radius: 10px;

  @media (max-width: 950px) {
    width: 200%;
  }
}

form input {
  margin: 1rem 20%;
  width: 60%;
}

select {
  margin: 1rem 20%;
  width: 60%;
}

.contact-form {
  padding: 6rem 6rem;
  @media (max-width: 950px) {
    padding: 2rem 2rem 8rem 2rem;
  }
}

label {
  width: 100%;
  text-align: center;
}

textarea {
  width: 90%;
  margin: 1rem 0rem;
}

.submit-btn {
  margin-left: 37.5%;
  margin-bottom: 2rem;
  width: 25%;
}

.first-label {
  margin-top: 2rem;
}

.form-text {
  margin-left: 0.25rem;
  text-align: center;
}

textarea.form-control {
  height: auto;
  width: 80%;
  margin-left: 10%;
}

.footer-center {
  text-align: center;
}

a.book {
  color: #fa9641;
}

.thankyou {
  padding-top: 6rem;
  padding-bottom: 24rem;
}

.navbar-expand-md {
  @media (min-width: 768px) {
    flex-flow: row nowrap;
    justify-content: space-around;
  }
}

.navbar-light .navbar-brand {
  color: white;
  &:hover {
    color: #fa9641;
  }
}
